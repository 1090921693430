import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";


import 'swiper/css';

import './global.css';
import './styles.css';

/* HEADER IMAGES */
import logoCaucaiaImg from './images/logo_caucaia.png';

import backgroundBannerYellow from './images/background_banner.svg';

/* HERO IMAGES */
//import heroImage from './images/image_hero.svg';

/* COURSERS OFERRED */
import backgroundEffectImg from './images/background_effect.png';

import arrowLeftIcon from './images/icons/arrow_left.svg';
import arrowRightIcon from './images/icons/arrow_right.svg';

/* ABOUT PROJECT IMAGES */
import logoProsperarImg from './images/logo_prosperar.svg';
//import logoCaucaiaVetorImg from './images/logo_caucaia2.svg';
import lampIcon from './images/icons/lamp.svg';
import starIcon from './images/icons/star.svg';
import star2Icon from './images/icons/star2.svg';
import ray1Icon from './images/icons/ray1.svg';
import ray2Icon from './images/icons/ray2.svg';
import ray3Icon from './images/icons/ray2.svg';
import folderIcon from './images/icons/folder.svg';
import notebookIcon from './images/icons/notebook.svg';

import educationIcon from './images/icons/education.png';
import cashIcon from './images/icons/cash.png';
import classroomIcon from './images/icons/classroom.png';
import clockIcon from './images/icons/clock.png';
import booksIcon from './images/icons/books.png';
import groupIcon from './images/icons/group.png';

import graduationHatIcon from './images/icons/graduationHat.svg';
import idCardIcon from './images/icons/id.svg';

/* FOOTER IMAGES */
import logoCaucaiaFooterImg from './images/logo_caucaia_footer.png';
import logoCafImg from './images/logo_caf.png';
import logoCedepanImg from './images/logo_cedepan.png';
import whatsappIcon from './images/icons/whatsapp.svg';
import emailIcon from './images/icons/email.svg';
import instagramIcon from './images/icons/instagram.svg';
import internetIcon from './images/icons/internet.svg';
import facebookIcon from './images/icons/internet.svg';

import menuIcon from './images/icons/menu.svg';
import bannerMobileImg from './images/bannermobile.svg';
import closeIcon from './images/icons/close.svg';
import { Menu, MenuItem } from '@szhsin/react-menu';

function App() {
  const [mySwiper, setMySwiper] = useState();
  const [menuHamburguerActive, setMenuHamburguerActive] = useState(false);

  return (
    <div className="container">

      <div className={`menuHamburguer ${menuHamburguerActive && 'active'}`}>
        <button className='close' onClick={() => setMenuHamburguerActive(false)}>
          <img src={closeIcon} alt="Fechar" />
        </button>

        <nav>
          <ul>
          { /* 
          <li><a target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/cgeral.pdf'>Calendário Geral</a></li>
            <li><a target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/ceadhibrido.pdf'>Calendário de cursos EaD e Híbridos</a></li>
            <li><a target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/cpresenciais.pdf'>Calendário de cursos presenciais</a></li>
          
          */ }
            
            <li><a target={'_blank'} rel="noreferrer" href='https://forms.gle/69XiFpkH5QNmz2266'>Inscreva-se</a></li>
            <li><a target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/manual_do_aluno_prosperar.pdf'>Manual do aluno</a></li>
            <li><a target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/cpresenciais.pdf'>Primeiro Acesso à Plataforma</a></li>
          </ul>
          <a
            id="botacessarmoodle"
            href="#videos"
            role="button"
            onClick={() => setMenuHamburguerActive(false)}
          >
            Vídeo Tutorial
          </a>
        </nav>
      </div>

      <header className="headerMain">
        <img src={logoCaucaiaImg} alt="Logo Caucaia" />
        <nav>
          <ul>
          { /* 
          
          <li>
              <Menu
                menuButton={<span>Calendário de Cursos</span>}
                arrow
                transition
              >
                <MenuItem target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/cgeral.pdf'>Calendário Geral</MenuItem>
                <MenuItem target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/ceadhibrido.pdf'>Calendário de cursos EaD e Híbridos</MenuItem>
                <MenuItem target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/cpresenciais.pdf'>Calendário de cursos presenciais</MenuItem>
              </Menu>
              <li><a target={'_blank'} href='https://ead.eadcedepam.com.br/static/cgeral.pdf' rel="noreferrer">Calendário de Cursos</a></li>
            </li>
 
          */ }
            
            
            <li><a target={'_blank'} href='https://forms.gle/69XiFpkH5QNmz2266' rel="noreferrer">Inscreva-se</a></li>
            <li>

            <Menu
                menuButton={<span>Manuais</span>}
                arrow
                transition
              >
                <MenuItem target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/manual_do_aluno_prosperar.pdf'>Manual do Aluno</MenuItem>
                <MenuItem target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br/static/primeiroAcesso.pdf'>Primeiro Acesso à Plataforma</MenuItem>
              </Menu>
            </li>
            

           </ul>
        </nav>
        <a id="botacessarmoodle" href="#videos" role="button">Vídeo Tutorial</a>
      </header>

      <div className='hero'>
        <h1>Prefeitura de <b>Caucaia</b> oferta <br /> <span>+50 cursos</span> <br /> de Educação profissional, <br /> <b>totalmente gratuitos</b></h1>

        <p><span>Capacitar</span> para <span>inovar</span> e <span>desenvolver</span></p>
        <a id="botacessarcursos" href="http://ead.eadcedepam.com.br" role="button">Acesse nossa plataforma</a>
      
     </div>

      <div className='heroMobile'>
        <button className='menu' onClick={() => setMenuHamburguerActive(true)}>
          <img src={menuIcon} alt="Menu" />
        </button>

        <img src={bannerMobileImg} alt="Banner" />

        <h1>Prefeitura de <b>Caucaia</b> oferta <br /> <span>+50 cursos</span> <br /> de Educação profissional, <br /> <b>totalmente gratuitos</b></h1>
        
        <a id="botacessarcursosmobile" href="http://ead.eadcedepam.com.br" role="button">Acesse nossa plataforma</a>
      </div>

      <div id="videos" className='coursesOffered'>
      <h1>Ambientação na plataforma</h1>
      <span></span>
      <iframe width="100%" style={{ maxWidth: '640px', minWidth: '21rem' }} height="360" src="https://www.youtube.com/embed/f0Mj9S99gHM" title="Ambientação da plataforma EAD - EAD CEDEPAM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

      <div id="cursosoferecidos" className='coursesOffered'>
        <img src={backgroundEffectImg} alt="Efeito" />

        <h1>Cursos oferecidos</h1>
    
        <div className="swiperConteiner">
          <div className="buttonContainer">
            <button onClick={() => {
              mySwiper.slidePrev();
            }} type="button">
              <img src={arrowLeftIcon} alt="Esquerda" />
            </button>
          </div>
          <Swiper
            onInit={(ev) => {
              setMySwiper(ev);
            }}
            slidesPerView={3}
            spaceBetween={0}
            slidesPerGroup={1}
            speed={800}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="swiper"
          >
            
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className="food">Alimentação</h3>
                <ul>
                  <li>
                    <p>
                      AUXILIAR DE COZINHA <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CULINÁRIA: PRODUÇÃO DE DOCES E BOLOS FINOS <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CULINÁRIA: PRODUÇÃO DE PÃES E SALGADOS <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GASTRONOMIA: FORMAÇÃO DE COZINHEIROS - BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GASTRONOMIA: FORMAÇÃO DE COZINHEIROS - INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GASTRONOMIA: FORMAÇÃO DE COZINHEIROS - AVANÇADO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                     HIGIENE E MANIPULAÇÃO DE ALIMENTOS <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='confection'>Confecção</h3>
                <ul>
                  <li>
                    <p>
                      BORDADO - BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      BORDADO - INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - INICIANTE <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - AVANÇADO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MODELISTA BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MODELISTA INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='aesthetics'>Estética</h3>
                <ul>
                <li>
                    <p>
                      MANICURE E PEDICURE <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>                  
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='empreended'>Empreendedorismo</h3>
                <ul>
                  <li>
                    <p>
                      COOPERATIVISMO E ASSOCIATIVISMO: UNINDO FORÇAS PARA VENCER <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EVOLUÇÃO E MARKETING PESSOAL EM REDES SOCIAS <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO DE PEQUENOS NEGÓCIOS <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO FINANCEIRA E FUNDAMENTOS CONTÁBEIS <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MARKETING DIGITAL E NEGÓCIOS ONLINE PARA O MICROEMPREENDEDOR <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      PLANEJAMENTO ESTRATÉGICO PARA EMPREENDEDORES <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      PRECIFICAÇÃO: FORMATAÇÃO E DEFINIÇÃO DO PREÇO DE VENDA <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      TÉCNICAS DE EMPREENDEDORISMO <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='food'>Formação Geral</h3>
                <ul>
                <li>
                    <p>
                    FORMAÇÃO PARA CATADORES E RECICLADORES <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                    AUXILIAR DE ESCRITÓRIO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO DE PROJETOS - BÁSICO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO DE PROJETOS - INTERMEDIÁRIO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      VENDAS: COMO SER UM BOM VENDEDOR <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>               
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='hotelturis'>Hotelaria e Turismo</h3>
                <ul>
                <li>
                    <p>
                      CAMAREIRA <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                      GARÇOM <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      INGLÊS - BÁSICO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      INGLÊS INTERMEDIÁRIO<br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      RECEPCIONISTA<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>               
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='pragricola'>Produção Agrícola</h3>
                <ul>
                <li>
                    <p>
                      AGROECOLOGIA <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                      APROVEITAMENTO DAS ÁGUAS<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      COMPOSTAGEM<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                    ESCOAMENTO DE PRODUÇÃO E LOGÍSTICA<br />
                    <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MANEJO DE ANIMAIS<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MINHOCULTURA<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>   
                  <li>
                    <p>
                      PISCICULTURA<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                      PRODUÇÃO ORGÂNICA<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>                      
                </ul>
              </div>
            </SwiperSlide>
            <SwiperSlide className='slide'>
              <div className='courseContainer'>
                <h3 className='food'>Tecnologia da Informação</h3>
                <ul>
                <li>
                    <p>
                      AUTOCAD - BÁSICO <br />
                      <span>Híbrido - 20h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                    AUTOCAD - INTERMEDIÁRIO<br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                    AUTOCAD - AVANÇADO<br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      DASHBOARD WEB<br />
                      <span>EAD - 60h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EXCEL - BÁSICO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EXCEL - INTERMEDIÁRIO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EXCEL - AVANÇADO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      PYTHON PARA CIÊNCIA DE DADOS <br />
                      <span>EAD - 60h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      WORDPRESS - BÁSICO <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      WORDPRESS - INTERMEDIÁRIO <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      WORDPRESS - AVANÇADO <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  
                           
                </ul>
              </div>
            </SwiperSlide>

          </Swiper>
          <div className="buttonContainer">
            <button onClick={() => {
              mySwiper.slideNext();
            }} type="button">
              <img src={arrowRightIcon} alt="Direita" />
            </button>
          </div>
        </div>

        <div className="swiperConteinerMobile">
        <div className='courseContainer'>
                <h3 className="food">Alimentação</h3>
                <ul>
                  <li>
                    <p>
                      AUXILIAR DE COZINHA <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CULINÁRIA: PRODUÇÃO DE DOCES E BOLOS FINOS <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CULINÁRIA: PRODUÇÃO DE PÃES E SALGADOS <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GASTRONOMIA: FORMAÇÃO DE COZINHEIROS - BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GASTRONOMIA: FORMAÇÃO DE COZINHEIROS - INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GASTRONOMIA: FORMAÇÃO DE COZINHEIROS - AVANÇADO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                     HIGIENE E MANIPULAÇÃO DE ALIMENTOS <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className='courseContainer'>
                <h3 className='confection'>Confecção</h3>
                <ul>
                  <li>
                    <p>
                      BORDADO - BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      BORDADO - INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - INICIANTE <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - INTERMEDIÁRIO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      CORTE E COSTURA - AVANÇADO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MODELISTA BÁSICO <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MODELISTA INTERMEDIÁRIO<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className='courseContainer'>
                <h3 className='aesthetics'>Estética</h3>
                <ul>
                <li>
                    <p>
                      MANICURE E PEDICURE <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>                  
                </ul>
              </div>
              <div className='courseContainer'>
                <h3 className='empreended'>Empreendedorismo</h3>
                <ul>
                  <li>
                    <p>
                      COOPERATIVISMO E ASSOCIATIVISMO: UNINDO FORÇAS PARA VENCER <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EVOLUÇÃO E MARKETING PESSOAL EM REDES SOCIAS <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO DE PEQUENOS NEGÓCIOS <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO FINANCEIRA E FUNDAMENTOS CONTÁBEIS <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MARKETING DIGITAL E NEGÓCIOS ONLINE PARA O MICROEMPREENDEDOR <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      PLANEJAMENTO ESTRATÉGICO PARA EMPREENDEDORES <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      PRECIFICAÇÃO: FORMATAÇÃO E DEFINIÇÃO DO PREÇO DE VENDA <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      TÉCNICAS DE EMPREENDEDORISMO <br />
                      <span>EAD - 20h/a</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className='courseContainer'>
                <h3 className='food'>Formação Geral</h3>
                <ul>
                <li>
                    <p>
                      FORMAÇÃO PARA CATADORES E RECICLADORES <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                    AUXILIAR DE ESCRITÓRIO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO DE PROJETOS - BÁSICO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      GESTÃO DE PROJETOS - INTERMEDIÁRIO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      VENDAS: COMO SER UM BOM VENDEDOR <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>               
                </ul>
              </div>

              <div className='courseContainer'>
                <h3 className='hotelturis'>Hotelaria e Turismo</h3>
                <ul>
                <li>
                    <p>
                      CAMAREIRA <br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                      GARÇOM <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      INGLÊS - BÁSICO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      INGLÊS INTERMEDIÁRIO<br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      RECEPCIONISTA<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>               
                </ul>
              </div>

              <div className='courseContainer'>
                <h3 className='pragricola'>Produção Agrícola</h3>
                <ul>
                <li>
                    <p>
                      AGROECOLOGIA <br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                      APROVEITAMENTO DAS ÁGUAS<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      COMPOSTAGEM<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                    ESCOAMENTO DE PRODUÇÃO E LOGÍSTICA<br />
                    <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MANEJO DE ANIMAIS<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      MINHOCULTURA<br />
                      <span>Presencial - 20h/a</span>
                    </p>
                  </li>   
                  <li>
                    <p>
                      PISCICULTURA<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                      PRODUÇÃO ORGÂNICA<br />
                      <span>Presencial - 40h/a</span>
                    </p>
                  </li>                      
                </ul>
              </div>

              <div className='courseContainer'>
                <h3 className='food'>Tecnologia da Informação</h3>
                <ul>
                <li>
                    <p>
                      AUTOCAD - BÁSICO <br />
                      <span>Híbrido - 20h/a</span>
                    </p>
                  </li>  
                  <li>
                    <p>
                    AUTOCAD - INTERMEDIÁRIO<br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                    AUTOCAD - AVANÇADO<br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      DASHBOARD WEB<br />
                      <span>EAD - 60h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EXCEL - BÁSICO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EXCEL - INTERMEDIÁRIO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      EXCEL - AVANÇADO <br />
                      <span>Híbrido - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      PYTHON PARA CIÊNCIA DE DADOS <br />
                      <span>EAD - 60h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      WORDPRESS - BÁSICO <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      WORDPRESS - INTERMEDIÁRIO <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      WORDPRESS - AVANÇADO <br />
                      <span>EAD - 40h/a</span>
                    </p>
                  </li>
                  
                           
                </ul>
              </div>

        </div>
      </div>

      <div className='imagesContainer'>
        <img src={logoProsperarImg} alt="Prosperar" />
      </div>

      <img className="backgroundBannerYellow" src={backgroundBannerYellow} alt="Banner Amarelo" />

      <div id="sobre" className="aboutProject">
        <header>
          <h1>Sobre o projeto</h1>
          <div>
            <img src={logoProsperarImg} alt="Logo projeto prosperar" />
          </div>
        </header>

        <div className="cardMain">
          <p>A <span>Prefeitura de Caucaia</span> apresenta o Projeto Prosperar - Capacitar para Inovar e Desenvolver, que tem como objetivo qualificar e profissionalizar os integrantes dos arranjos produtivos locais, e de outras organizações de caráter produtivo, bem como os microempreendedores individuais e o público em geral, visando qualificar cerca de 10.000 pessoas no município de Caucaia.</p>

          <img id="cardMain_lamp" src={lampIcon} alt="Ícone lâmpada" />
          <img id="cardMain_star" src={starIcon} alt="Ícone estrela" />
          <img id="cardMain_ray1" src={ray1Icon} alt="Ícone raio" />
          <img id="cardMain_ray2" src={ray2Icon} alt="Ícone raio" />
          <img id="cardMain_ray3" src={ray3Icon} alt="Ícone raio" />
          <img id="cardMain_ray4" src={ray1Icon} alt="Ícone raio" />
          <img id="cardMain_graduationHat" src={graduationHatIcon} alt="Ícone chapéu" />
          <img id="cardMain_idCard" src={idCardIcon} alt="Ícone identidade" />
        </div>

        <div className='cardsContainer'>
          <div className='card'>
            <div className='iconContainer'>
              <img src={educationIcon} alt="Ícone capelo" />
            </div>
            <p>+ de 50 Cursos Profissionalizantes</p>
          </div>
          <div className='card'>
            <div className='iconContainer'>
              <img src={cashIcon} alt="Ícone preço" />
            </div>
            <p>Totalmente Gratuito</p>
          </div>
          <div className='card'>
            <div className='iconContainer'>
              <img src={classroomIcon} alt="Ícone sala de aula" />
            </div>
            <p>+ de 250 Turmas</p>
          </div>
        </div>

        <div className='cardsContainer'>
          <div className='card'>
            <div className='iconContainer'>
              <img src={clockIcon} alt="Ícone relógio" />
            </div>
            <p>9.120 Horas/aula</p>
          </div>
          <div className='card'>
            <div className='iconContainer'>
              <img src={booksIcon} alt="Ícone livros" />
            </div>
            <p>Capacitações em 8 áreas <br /> diferentes</p>
          </div>
          <div className='card'>
            <div className='iconContainer'>
              <img src={groupIcon} alt="Ícone consultoria" />
            </div>
            <p>6.000 horas de consultoria especializada</p>
          </div>
        </div>

        <div className="cardFooter">
          <p>Os cursos na modalidade EAD e Híbrido serão realizados através da plataforma educacional Moodle, onde serão utilizados vídeos, animações, material didático e fóruns para discussão. Já os cursos presenciais, serão ministrados em oficinas de ensino teórico e prático no polo presencial.</p>
        
          <img id="cardFooter_ray1" src={ray2Icon} alt="Ícone raio" />
          <img id="cardFooter_star" src={star2Icon} alt="Ícone estrela" />
          <img id="cardFooter_ray2" src={ray1Icon} alt="Ícone raio" />
          <img id="cardFooter_idCard" src={idCardIcon} alt="Ícone identidade" />
          <img id="cardFooter_folder" src={folderIcon} alt="Ícone pasta" />
          <img id="cardFooter_ray3" src={ray2Icon} alt="Ícone raio" />
          <img id="cardFooter_notebook" src={notebookIcon} alt="Ícone caderno" />
          <img id="cardFooter_ray4" src={ray1Icon} alt="Ícone raio" />
        </div>
      </div>

      <footer>
        <div className='companies-container'>
          <img src={logoCaucaiaFooterImg} alt="Logo Caucaia" className="footer-caucaia-logo" />
          <div className='companies'>
            <img src={logoCafImg} alt="Logo CAF" />
            <img src={logoCedepanImg} alt="Logo Cedepan" />
          </div>
        </div>
        <div className='contacts'>
          <h3>Quer saber mais sobre o projeto?</h3>
          <div className='contactsList'>
            <p>
            <a target={'_blank'} rel="noreferrer" href='https://api.whatsapp.com/send?phone=5585997173545'>
              <img src={whatsappIcon} alt="Whatsapp" />
              +55 85 99717-3545
              </a>
            </p>
            <p>
            <a target={'_blank'} rel="noreferrer" href='mailto:projeto.prosperar@cedepam.com.br'>
              <img src={emailIcon} alt="Email" />
              Prosperar Caucaia
              </a>
            </p>
            <p>
            <a target={'_blank'} rel="noreferrer" href='https://instagram.com/prosperar_caucaia?igshid=NDc0ODY0MjQ='>
              <img src={instagramIcon} alt="Instagram" />
              @prosperar_caucaia
             </a> 
            </p>
            <p>
              <a target={'_blank'} rel="noreferrer" href='https://www.cedepam.com.br'>
              <img src={internetIcon} alt="Internet" />
              www.cedepam.com.br
              </a>
            </p>

            <p>
              <a target={'_blank'} rel="noreferrer" href='https://ead.eadcedepam.com.br'>
              <img src={internetIcon} alt="Nossa plataforma" />
              Nossa plataforma
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
